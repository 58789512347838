import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import get from 'lodash/get'
import { Popover, Tooltip } from 'antd'

import ListItem from '../../components/ListItem'
import ListPanel from '../../components/ListPanel'
import Title from '../../components/Title'
import Subtitle from '../../components/Subtitle'
import { dtcItemKey } from '../../helpers/utils'
import Comparator from '../../helpers/comparator'
import { PINOUT_VIEW, VEHICLE_VIEW } from '../../constants'
import IconWarning from '../../components/Icons/IconWarning'
import MemoButton, { EditIcon, AddIcon } from './DTCMemoButton'
import { getShortDescription } from '../../helpers/dtc'
import DTCMemoTooltip from './DTCMemoTooltip'
import DTCMemoPopup from './DTCMemoPopup'
import IconInfo from '../Icons/IconInfo'

const MAX_DTC_TITLE_LEN = 13

function MisconfiguredDTCContent({ dtc }) {
  return (
    <div className="dtc-popover-content">
      <div className="title">
        <FormattedMessage id="tracer.dtcListPanel.popover.title" />
      </div>
      <div className="block">
        <div className="block-label">
          <FormattedMessage id="tracer.dtcListPanel.popover.harnessPN" />
        </div>
        <div className="block-text">{dtc.harness_pn}</div>
      </div>
      <div className="block">
        <div className="block-label">
          <FormattedMessage id="tracer.dtcListPanel.popover.harnessDescription" />
        </div>
        <div className="block-text">{dtc.harness_description}</div>
      </div>
    </div>
  )
}

function isSelectedDtc(toggledOnDtc, currentDtc, module) {
  const dtcCodeMatched =
    get(toggledOnDtc, 'dtc_code') === get(currentDtc, 'dtc_code')
  const acronymMatched =
    get(toggledOnDtc, 'module.acronym') === get(module, 'acronym')

  return dtcCodeMatched && acronymMatched
}

function isDisabledBySelectedTab(currentTab) {
  return currentTab === PINOUT_VIEW || currentTab === VEHICLE_VIEW
}

function buildTitle(module, intl) {
  if (module.connector_alias) {
    return `${intl.formatMessage({ id: 'tracer.dtcListPanel.module' })} ${module.acronym} (${module.connector_alias})`
  }
  return `${intl.formatMessage({ id: 'tracer.dtcListPanel.module' })}  ${module.acronym}`
}

const DTCItem = (props) => {
  const {
    module,
    highlightedDtcs,
    toggledOnDtc,
    currentTab,
    onDtcClick,
    intl,
    makeModelId,
    isDtcMemoEditShown,
  } = props
  const [sortedDtcs, setSortedDtcs] = React.useState([])
  const [title, setTitle] = React.useState('')

  React.useEffect(() => {
    const comparator = Comparator.bind(null, 'asc', 'dtc_code')
    setSortedDtcs(module.dtcs.sort(comparator))
  }, [module])

  React.useEffect(() => {
    setTitle(buildTitle(module, intl))
  }, [module, intl])

  return (
    <ListPanel title={title} collapsible={true}>
      {sortedDtcs.map((dtc) => {
        const isSupported = dtc.is_supported
        const hasDtcMemo = !!dtc.memo && !!dtc.memo.memo
        if (!dtc.active) {
          return null
        }

        const indicated = highlightedDtcs.find((highlightedDtc) => {
          return (
            dtc.dtc_code === highlightedDtc.dtc_code &&
            dtc.module &&
            highlightedDtc.module &&
            dtc.module.acronym === highlightedDtc.module.acronym
          )
        })
        const isIndicated = !!indicated

        const isSelected = isSelectedDtc(toggledOnDtc, dtc, module)
        const isDisabled = isDisabledBySelectedTab(currentTab)
        const isMisconfigured = !!dtc.is_misconfigured
        const dtcCode = dtc.dtc_code
        const shortDtcCode =
          dtcCode.length > MAX_DTC_TITLE_LEN
            ? `${dtcCode.slice(0, 13)}...`
            : dtcCode
        const dtcDescription = getShortDescription(dtc, intl)

        const key = dtcItemKey(module, dtc)

        return (
          <ListItem
            id={key}
            key={key}
            handleListItemClick={() => onDtcClick(dtc)}
            indicate={isIndicated}
            misconfigured={isMisconfigured}
            selected={isSelected}
            disabled={isDisabled}
            height={80}
            unsupported={
              !isSupported && !isMisconfigured && dtc.memo && !dtc.memo.memo
            }
          >
            <DTCMemoPopup
              dtc={dtc}
              makeModelId={makeModelId}
              acronym={module.acronym}
              isSelected={isSelected}
            >
              <DTCMemoTooltip
                dtc={dtc}
                makeModelId={makeModelId}
                acronym={module.acronym}
                isSelected={isSelected}
              >
                <div>
                  <div className="title-container">
                    {isMisconfigured && (
                      <Popover
                        id={`misconfigured-dtc-popover-${dtc.dtc_code}`}
                        trigger="click"
                        placement="left"
                        content={() => <MisconfiguredDTCContent dtc={dtc} />}
                        overlayStyle={{
                          width: '250px',
                        }}
                      >
                        <IconWarning />
                      </Popover>
                    )}
                    <span className="w-100 flex">
                      <Title>
                        <Tooltip title={dtcCode} zIndex="9999" placement="left">
                          {shortDtcCode}
                        </Tooltip>
                      </Title>
                      {hasDtcMemo && <IconInfo />}
                      {isDtcMemoEditShown && (
                        <MemoButton
                          dtc={dtc}
                          makeModelId={makeModelId}
                          acronym={module.acronym}
                          editIcon={EditIcon}
                          addIcon={AddIcon}
                        />
                      )}
                    </span>
                  </div>
                  <Subtitle>{dtcDescription}</Subtitle>
                </div>
              </DTCMemoTooltip>
            </DTCMemoPopup>
          </ListItem>
        )
      })}
    </ListPanel>
  )
}

export default injectIntl(DTCItem)
